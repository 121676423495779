.container {
    max-width: 400px;
    margin: 20px auto;

    .wheel {
        animation: rotate 7.5s ease-out infinite alternate-reverse;
        transform-origin: center center;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(-180deg);
    }

    100% {
        transform: rotate(180deg);
    }
}
