.container {
    max-width: 400px;
    margin: 20px auto;

    .bingo {
        path {
            opacity: 0;
        }
    }

    &.visible {
        .circle {
            animation: flash 0.5s ease-in-out infinite alternate-reverse;

            &:nth-child(2n) {
                animation-delay: 0.25s;
            }

            &:nth-child(3n) {
                animation-delay: .5s;
            }

            &:nth-child(4n) {
                animation-delay: 0.75s
            }

            &:nth-child(5n) {
                animation-delay: 1.25s;
            }
        }

        .bingo {
            path {
                animation: flash 1s ease-out forwards;
                animation-delay: 1s;

                &:nth-child(2) {
                    animation-delay: 1.4s;
                }

                &:nth-child(3) {
                    animation-delay: 1.9s;
                }

                &:nth-child(4) {
                    animation-delay: 2.3s;
                }

                &:nth-child(5) {
                    animation-delay: 2.7s;
                }
            }
        }
    }
}

@keyframes flash {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
