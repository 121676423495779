.container {
    --color-black: #000;
    --color-red: #e84242;
    --color-orange: #FF4325;
    --color-white: #fff;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 20px;
    aspect-ratio: 63/88;
    background-color: var(--color-white);
    color: var(--color-black);

    .corner {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: var(--font-agrandir);
        font-weight: bold;
        gap: 5px;

        &.top {
            top: 15px;
            left: 15px;
        }

        &.bottom {
            right: 15px;
            bottom: 15px;
            transform: rotate(180deg);
            transform-origin: center;
        }
    }

    .number {
        font-size: 24px;
    }

    .suit {
        display: block;
        width: 20px;
        height: auto;
    }

    .text {
        color: var(--color-orange);
        font-family: var(--font-agrandir);
        font-weight: bold;
        opacity: 0;
        text-align: center;
        transition: opacity 0.5s ease-in-out;
        transition-delay: 0.5s;
    }

    &.red {
        color: var(--color-red);
    }

    &.visible {
        .text {
            opacity: 1;
        }
    }
}
