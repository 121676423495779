.container {
    max-width: 400px;
    margin: 20px auto;

    svg {
        animation: rotate 4s ease-in-out infinite alternate-reverse;
        transform-origin: center;
    }

    .dice {
        animation: move 3s ease-in-out infinite alternate-reverse;
        transform-origin: center;

        &:nth-child(2) {
            animation-delay: 1.5s;
        }
    }
}

@keyframes rotate {
    0% {
        transform: rotate(-3deg);
    }

    100% {
        transform: rotate(3deg);
    }
}

@keyframes move {
    0% {
        transform: translateY(0) rotate(3deg);
    }

    100% {
        transform: translateY(15px) rotate(-3deg);
    }
}
