.container {
    display: grid;
    max-width: var(--block-max-width-text);
    padding: 0 var(--spacing);
    margin: 40px auto;
    gap: var(--spacing-medium);
    grid-template-columns: 1fr;

    @media (--small-viewport) {
        gap: var(--spacing);
        grid-template-columns: 1fr 1fr;
    }
}
