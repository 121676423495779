.container {
    max-width: 320px;
    margin: 0 auto;

    .lose {
        opacity: 0;
    }

    &.visible {
        .lose {
            animation: flash 1s ease-out forwards;
            animation-delay: 1s;

            &:nth-child(2) {
                animation-delay: 1.5s;
            }

            &:nth-child(3) {
                animation-delay: 2s;
            }
        }
    }
}

@keyframes flash {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
