.container {
    max-width: 400px;
    margin: 20px auto;

    svg {
        animation: rotate 2s ease-in-out infinite alternate-reverse;
    }

    .tear {
        animation: flash 1s ease-in-out infinite alternate-reverse;

        &:nth-child(2) {
            animation-delay: 0.5s;
        }

        &:nth-child(3) {
            animation-delay: 0.5s;
        }

        &:nth-child(4) {
            animation-delay: 0.5s;
        }

        &:nth-child(5) {
            animation-delay: 0.5s;
        }
    }
}

@keyframes rotate {
    0% {
        transform: rotate(-1deg);
    }

    100% {
        transform: rotate(1deg);
    }
}

@keyframes flash {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
